import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { forEach } from 'lodash';
import useSWR from 'swr';
import moment from 'moment';
import makeStyles from '@mui/styles/makeStyles';

import { DataGrid } from '@mui/x-data-grid';

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Typography from '@mui/material/Typography';

import UserSearch from '../components/user-search';
import states from '../lib/states';
import ClientSearch from '../components/client-search';
import useUser from '../lib/useUser';

import { apiFetch } from '../lib/fetch';
import { MutedTypography } from '../lib/styles';
import Layout from '../components/layout';
import config from '../config';
import Download from '../components/download-csv';
// TODO fix custom button not registering color prop, remove mui button above
import {
  Button,
  TextField,
  Select,
  GridForm,
  MaskInput,
  TextArea,
} from '../lib/common';
import { cleanNumber, formatCurrency } from '../lib/util';

const useStyles = makeStyles(() => ({
  grow: {
    flexGrow: 1,
  },
  errTxt: {
    fontSize: '1.5em',
    color: 'red',
    margin: '10px',
  },
  form: {
    width: '80%',
    margin: 'auto',
  },
  saveBtnContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: '20px',
  },
  w200: {
    width: '200px',
  },
  w300: {
    width: '300px',
  },
  w400: {
    width: '400px',
  },
  writingAgent: {
    paddingTop: '20px',
    paddingBottom: '20px',
  },
  splitBad: {
    color: 'red',
  },
  gridContainer: {
    width: '100%',
    display: 'flex',
    height: 350,
  },
}));

const paymentColumns = [
  { field: 'id', hide: true },
  {
    field: 'num',
    headerName: 'Agent #',
    align: 'right',
    width: 120,
  },
  {
    field: 'firstname',
    headerName: 'First Name',
    align: 'left',
    width: 150,
  },
  {
    field: 'lastname',
    headerName: 'Last Name',
    align: 'left',
    width: 150,
  },
  {
    field: 'amount',
    headerName: 'Amount',
    align: 'right',
    width: 150,
    renderCell: (params) => {
      return <span>{formatCurrency(Number(params.row.amount).toFixed(2))}</span>;
    },
  },
  {
    field: 'full_business_split',
    headerName: 'SH Business',
    align: 'right',
    width: 150,
    renderCell: (params) => {
      return <span>{formatCurrency(Number(params.row.full_business_split).toFixed(2))}</span>;
    },
  },
  {
    field: 'status',
    headerName: 'Status',
    align: 'right',
    width: 150,
  },
  {
    field: 'created_at',
    headerName: 'Created',
    align: 'right',
    width: 150,
    renderCell: (params) => {
      return <span>{params.row.created_at ? moment(params.row.created_at).format('YYYY-MM-DD') : ''}</span>;
    },
  },
];

const chargebackColumns = [
  { field: 'id', hide: true },
  {
    field: 'num',
    headerName: 'Agent #',
    align: 'right',
    width: 120,
  },
  {
    field: 'firstname',
    headerName: 'First Name',
    align: 'left',
    width: 150,
  },
  {
    field: 'lastname',
    headerName: 'Last Name',
    align: 'left',
    width: 150,
  },
  {
    field: 'amount',
    headerName: 'Amount',
    align: 'right',
    width: 150,
    renderCell: (params) => {
      return <span>{Number(params.row.amount).toFixed(2)}</span>;
    },
  },
  {
    field: 'type',
    headerName: 'Type',
    align: 'right',
    width: 180,
    renderCell: (params) => {
      const { row } = params;
      let value = row.type;
      if (value === 'STANDARD_PAYMENT') {
        value = 'CHARGEBACK PAYMENT';
      }
      return <span>{value}</span>;
    },
  },
  {
    field: 'created_at',
    headerName: 'Created',
    align: 'right',
    width: 150,
    renderCell: (params) => {
      return <span>{params.row.created_at ? moment(params.row.created_at).format('YYYY-MM-DD') : ''}</span>;
    },
  },
];

const logColumns = [
  { field: 'id', hide: true },
  {
    field: 'num',
    headerName: 'Agent #',
    align: 'right',
    width: 120,
  },
  {
    field: 'firstname',
    headerName: 'First Name',
    align: 'left',
    width: 150,
  },
  {
    field: 'lastname',
    headerName: 'Last Name',
    align: 'left',
    width: 150,
  },
  {
    field: 'type',
    headerName: 'Type',
    align: 'right',
    width: 150,
  },
  {
    field: 'created_at',
    headerName: 'Created',
    align: 'right',
    width: 150,
    renderCell: (params) => {
      const { created_at } = params.row;
      return <span>{moment(created_at).format('YYYY-MM-DD h:mma')}</span>;
    },
  },
  {
    field: 'notes',
    headerName: 'Notes',
    align: 'left',
    width: 350,
  },
];

const ReviewBSF = (props) => {
  const history = useHistory();
  const classes = useStyles();
  const auth = useUser();

  const [policy, setPolicy] = useState();
  const [inValidPolicy, setInValidPolicy] = useState(false);
  const [err, setErr] = useState('');
  const [submitted_at, setSubmitted_at] = useState(null);
  const [carrier_id, setCarrier_id] = useState();
  const [state, setState] = useState();
  const [personal, setPersonal] = useState(false);
  const [product_type_id, setProduct_type_id] = useState();
  const [policy_number, setPolicy_number] = useState();
  const [source_id, setSource_id] = useState('');
  const [funds_type_id, setFunds_type_id] = useState('');
  const [first_distribution, setFirst_distribution] = useState();
  const [life_target, setLife_target] = useState();
  const [death_benefit, setDeath_benefit] = useState();
  const [life_annual, setLife_annual] = useState();
  const [initial_funding_amount, setInitial_funding_amount] = useState();

  const [writingAgent, setWritingAgent] = useState(null);
  const [rejectNotes, setRejectNotes] = useState('');
  const [writing_agent_split, setWriting_agent_split] = useState(100);
  const [joint, setJoint] = useState(false);
  const [agent2, setAgent2] = useState();
  const [agent3, setAgent3] = useState();
  const [agent4, setAgent4] = useState();
  const [agent2Split, setAgent2Split] = useState(0);
  const [agent3Split, setAgent3Split] = useState(0);
  const [agent4Split, setAgent4Split] = useState(0);
  const [isWriter, setIsWriter] = useState(false);
  const [saving, setSaving] = useState(false);
  const [payments, setPayments] = useState([]);
  const [chargebacks, setChargebacks] = useState([]);
  const [log, setLog] = useState([]);
  const [client, setClient] = useState(null);
  const [type, setType] = useState(null);
  const [new_hh, setNew_hh] = useState(null);
  const [contract_basis, setContract_basis] = useState();
  const [additional_c_basis, setAdditional_c_basis] = useState();
  const [term_months, setTerm_months] = useState();
  const [bsfFiles, setBsfFiles] = useState([]);
  const [errors, setErrors] = useState({});
  const [note_interest_rate, setNote_interest_rate] = useState();
  const [canceled, setCanceled] = useState(false);

  const { data: carriers } = useSWR('/carriers?orderBy=name&sortOrder=asc&active=true');
  const { data: sources } = useSWR('/sources?orderBy=name&sortOrder=asc&active=true');
  const { data: productTypes } = useSWR('/product_types?orderBy=name&sortOrder=asc&active=true');
  const { data: fundsTypes } = useSWR('/client_fund_types?orderBy=order_val&sortOrder=asc&active=true');

  let policyNumLabel = 'Policy Number';
  let submittedDateLabel = 'Policy Effective Date';
  if (type === 'ANNUITY') {
    policyNumLabel = 'Contract Number';
    submittedDateLabel = 'Policy Effective Date';
  } else if (type === 'HEDGEHOG') {
    policyNumLabel = 'Contract ID';
    submittedDateLabel = 'Note Effective Date';
  } else if (type === 'LANDING_ROCK') {
    policyNumLabel = 'Account Number';
    submittedDateLabel = 'Submitted On';
  } else {
    policyNumLabel = 'Policy Number';
    submittedDateLabel = 'Policy Effective Date';
  }
  const filteredCarriers = [];
  const filteredProductTypes = [];

  if (carriers) {
    if (type === 'HEDGEHOG') {
      if (!carrier_id) {
        carriers.forEach((c) => {
          if (c.code === 'HEDGEHOG') {
            setCarrier_id(c.id);
          }
        });
      }
    } else if (type === 'LANDING_ROCK') {
      if (!carrier_id) {
        carriers.forEach((c) => {
          if (c.code === 'LANDING_ROCK') {
            setCarrier_id(c.id);
          }
        });
      }
    } else {
      carriers.forEach((c) => {
        if ((c.code !== 'HEDGEHOG') && (c.code !== 'LANDING_ROCK')) {
          filteredCarriers.push(c);
        }
      });
    }
  }

  if (productTypes) {
    if (type === 'ANNUITY') {
      productTypes.forEach((c) => {
        if (c.annuity) {
          filteredProductTypes.push(c);
        }
      });
    } else if (type === 'LIFE') {
      productTypes.forEach((c) => {
        if (!c.annuity) {
          filteredProductTypes.push(c);
        }
      });
    }
  }

  let editable = false;
  if (isWriter && policy && ['REJECTED', 'OPEN', 'PENDING_AGENTS'].includes(policy.status)) {
    editable = true;
  }
  let closedEditable = false;
  if (isWriter && policy && policy.status === 'CLOSED') {
    closedEditable = true;
  }

  const { id } = useParams();

  let totalSplits = Number(writing_agent_split);
  if (agent2) {
    totalSplits += Number(agent2Split);
  }
  if (agent3) {
    totalSplits += Number(agent3Split);
  }
  if (agent4) {
    totalSplits += Number(agent4Split);
  }

  const validSplits = () => {
    if (!joint) {
      return true;
    }
    if (!writing_agent_split) {
      return false;
    }
    if (!agent2 && !agent3 && !agent4) {
      return false;
    }
    if (agent2 && !Number(agent2Split)) {
      return false;
    }
    if (agent3 && !Number(agent3Split)) {
      return false;
    }
    if (agent4 && !Number(agent4Split)) {
      return false;
    }
    return totalSplits === 100;
  };

  let disabled = !client
  || !carrier_id
  || !policy_number
  || !source_id
  || !validSplits()
  || saving;

  if (type === 'HEDGEHOG') {
    disabled = disabled || (new_hh === null) || !term_months;
    if (new_hh === true) {
      disabled = disabled || !contract_basis;
    } else if (new_hh === false) {
      disabled = disabled || !additional_c_basis || !contract_basis;
    }
    disabled = disabled || !submitted_at;
  } else if (type === 'LANDING_ROCK') {
    disabled = disabled || !initial_funding_amount;
  } else {
    disabled = disabled || !(first_distribution || ((Number(first_distribution) === 0) && (first_distribution !== '')));
    disabled = disabled || !product_type_id;
    disabled = disabled || (!life_target && !life_annual);
  }

  const setAgents = (agentList) => {
    forEach(agentList, (partner, idx) => {
      const agentDetail = {
        id: partner.agent,
        email: partner.email,
        firstname: partner.firstname,
        lastname: partner.lastname,
      };
      if (idx === 0) {
        setAgent2(agentDetail);
        setAgent2Split(Number(partner.split));
      }
      if (idx === 1) {
        setAgent3(agentDetail);
        setAgent3Split(Number(partner.split));
      }
      if (idx === 2) {
        setAgent4(agentDetail);
        setAgent4Split(Number(partner.split));
      }
    });
  };

  const setPolicyProps = (p) => {
    setIsWriter(p.writing_agent === auth.id);
    setWriting_agent_split(Number(p.writing_agent_split));
    setJoint(p.joint);

    setSubmitted_at(p.submitted_at);
    setCarrier_id(p.carrier_id);
    setState(p.state);
    setPersonal(p.personal);
    setProduct_type_id(p.product_type_id);
    setPolicy_number(p.policy_number);
    setSource_id(p.source_id);
    setFunds_type_id(p.funds_type_id);
    setInitial_funding_amount(p.initial_funding_amount);
    setFirst_distribution(formatCurrency(p.first_distribution));
    setLife_target(formatCurrency(p.life_target));
    setDeath_benefit(formatCurrency(p.death_benefit));
    setLife_annual(formatCurrency(p.life_annual));
    setContract_basis(formatCurrency(p.contract_basis));
    setAdditional_c_basis(formatCurrency(p.additional_c_basis));
    setTerm_months(p.term_months);
    setType(p.type);
    setNew_hh(p.new_hh);
    setNote_interest_rate(p.note_interest_rate);
    setCanceled(p.canceled);
  };

  useEffect(() => {
    apiFetch(`/bsf/${id}/details`)
      .then((res) => {
        setAgents(res[1]);
        setWritingAgent(res[3]);
        setPolicyProps(res[0]);
        setPolicy(res[0]);
        setClient(res[6]);
        setPayments(res[4]);
        setLog(res[5]);
        setBsfFiles(res[7]);
        setChargebacks(res[9]);
      })
      .catch((e) => {
        let err = e.toString();
        if (e.status === 404) {
          err = 'BSF Invalid or Deleted';
        }
        setInValidPolicy(true);
        setErr(err);
      });
  }, []);

  const deletePolicy = async () => {
    try {
      await apiFetch(`/bsf/${id}`, {
        method: 'DELETE',
      });
      history.push('/bsfs');
    } catch (err) {
      setErr(err.toString());
    }
  };

  const handleSubmit = (bool) => {
    const data = {
      submitted_at,
      carrier_id,
      product_type_id,
      policy_number: String(policy_number).trim(),
      first_distribution: Number(cleanNumber(first_distribution)) || undefined,
      state,
      source_id,
      // funds_type_id: funds_type_id || undefined,
      life_target: Number(cleanNumber(life_target)) || undefined,
      death_benefit: Number(cleanNumber(death_benefit)) || undefined,
      life_annual: Number(cleanNumber(life_annual)) || undefined,
      contract_basis: Number(cleanNumber(contract_basis)) || undefined,
      new_hh,
      additional_c_basis: Number(cleanNumber(additional_c_basis)) || null,
      term_months: term_months || undefined,
      // note_interest_rate: Number(cleanNumber(note_interest_rate)) || undefined,
      accepted: bool,
      rejectNotes,
    };

    apiFetch.put(`/bsf/${id}/user/${auth.id}/approveBSF`, data);
    history.push('/bsfs');
  };

  const handleResubmit = async () => {
    setSaving(true);
    setErr('');

    const data = {
      client_id: client.id,
      submitted_at,
      carrier_id,
      product_type_id,
      policy_number: String(policy_number).trim(),
      first_distribution: Number(cleanNumber(first_distribution)) || undefined,
      state,
      source_id,
      funds_type_id: funds_type_id || undefined,
      initial_funding_amount: Number(cleanNumber(initial_funding_amount)) || null,
      life_target: Number(cleanNumber(life_target)) || undefined,
      death_benefit: Number(cleanNumber(death_benefit)) || undefined,
      life_annual: Number(cleanNumber(life_annual)) || undefined,
      joint,
      personal,
      writing_agent_split,
      contract_basis: Number(cleanNumber(contract_basis)) || undefined,
      new_hh,
      additional_c_basis: Number(cleanNumber(additional_c_basis)) || 0,
      term_months: term_months || undefined,
      note_interest_rate: Number(cleanNumber(note_interest_rate)) || undefined,
      splits: [],
    };

    if (!joint) {
      data.writing_agent_split = 100;
    } else {
      if (agent2) {
        data.splits.push({ agent: agent2.id, split: agent2Split });
      }
      if (agent3) {
        data.splits.push({ agent: agent3.id, split: agent3Split });
      }
      if (agent4) {
        data.splits.push({ agent: agent4.id, split: agent4Split });
      }
    }

    try {
      await apiFetch.put(`/bsf/${id}/resubmit/${policy.writing_agent}`, data);
      history.push('/bsfs');
    } catch (e) {
      setErr(e.toString());
      setSaving(false);
    }
  };

  const handleClosedEdit = async () => {
    setSaving(true);
    setErr('');

    const data = {
      submitted_at,
      note_interest_rate: note_interest_rate || null,
      contract_basis: contract_basis || null,
      additional_c_basis: additional_c_basis || null,
      term_months: term_months || null,
      canceled,
    };

    try {
      await apiFetch.put(`/bsf/${id}/closed/${auth.id}`, data);
      history.push('/bsfs');
    } catch (e) {
      setErr(e.toString());
      setSaving(false);
    }
  };

  const createChangeHandler = (setter) => {
    const func = (e) => {
      const { value } = e.target || e;
      setter(value);
    };
    return func;
  };

  const handleDateChange = (e) => {
    const newValue = moment(e.target.value).toISOString();
    const isError = moment(newValue).isAfter(moment());
    setErrors({ ...errors, submitted_at: isError });
    setSubmitted_at(newValue);
  };

  return (
    <Layout>
      <div style={classes.mainBody}>
        <div className={classes.errTxt}>{err?.toString()}</div>
        {inValidPolicy ? '' : (
          <Paper sx={{ margin: '5px' }}>
            {policy && writingAgent && sources && carriers && productTypes ? (
              <form className={classes.form}>
                <Box py={3}>
                  <div>
                    <MutedTypography variant="h6">
                      BSF TYPE: {type}
                    </MutedTypography>
                  </div>
                  <MutedTypography variant="h5" sx={{ marginTop: 5 }}>
                    Writing Agent: {writingAgent.firstname} {writingAgent.lastname}
                  </MutedTypography>
                </Box>
                <GridForm>
                  <Grid container spacing={2}>
                    <TextField
                      label={submittedDateLabel}
                      value={submitted_at}
                      maxDate={new Date()}
                      name="submitted_at"
                      onChange={handleDateChange}
                      type="date"
                      required={true}
                      disabled={!editable && !closedEditable}
                    />
                    <Grid item xs={6}>
                      <ClientSearch
                        defaultValue=""
                        value={client}
                        onChange={setClient}
                        disabled={!editable}
                      />
                    </Grid>
                  </Grid>
                  {(type !== 'HEDGEHOG') && (type !== 'LANDING_ROCK') ? (
                    <Grid container spacing={2}>
                      <Select
                        labelId="demo-simple-select-label"
                        label="Carrier"
                        value={carrier_id}
                        disabled={!editable}
                        name="carrier_id"
                        onChange={createChangeHandler(setCarrier_id)}
                      >
                        {filteredCarriers
                          .filter((carrier) => carrier.active === true)
                          .map((carrier) => <MenuItem value={carrier.id} key={carrier.id}>{carrier.name}</MenuItem>)}
                      </Select>
                      <Select
                        labelId="demo-simple-select-label"
                        label="Product"
                        value={product_type_id}
                        disabled={!editable}
                        name="carrier_id"
                        onChange={createChangeHandler(setProduct_type_id)}
                      >
                        {filteredProductTypes
                          .filter((pt) => pt.active === true)
                          .map((pt) => <MenuItem value={pt.id} key={pt.id}>{pt.name}</MenuItem>)}
                      </Select>
                      <Select
                        labelId="demo-simple-select-label"
                        label="Client's State"
                        value={state}
                        disabled={!editable}
                        name="state"
                        onChange={createChangeHandler(setState)}
                      >
                        {states.map((state) => <MenuItem value={state[0]} key={state[0]}>{state[1]}</MenuItem>)}
                      </Select>
                    </Grid>
                  ) : ''}
                  <Grid container spacing={2}>
                    <TextField
                      label={policyNumLabel}
                      defaultValue=""
                      value={policy_number}
                      disabled={!editable}
                      name="policy_number"
                      onChange={createChangeHandler(setPolicy_number)}
                    />
                    <Select
                      labelId="demo-simple-select-label"
                      label={type === 'HEDGEHOG' ? 'Client Source' : 'Source'}
                      value={source_id}
                      disabled={!editable}
                      name="source_id"
                      onChange={createChangeHandler(setSource_id)}
                    >
                      {sources
                        .filter((source) => source.active === true)
                        .map((source) => <MenuItem value={source.id} key={source.id}>{source.name}</MenuItem>)}
                    </Select>
                    {type === 'HEDGEHOG' ? (
                      <Select
                        labelId="demo-simple-select-label"
                        label="Money Source"
                        value={funds_type_id}
                        name="funds_type_id"
                        disabled={!editable}
                        onChange={createChangeHandler(setFunds_type_id)}
                      >
                        {fundsTypes
                          .map((ft) => <MenuItem value={ft.id} key={ft.id}>{ft.name}</MenuItem>)}
                      </Select>
                    ) : ''}
                    {type === 'LANDING_ROCK' ? (
                      <MaskInput
                        mask_type="currency"
                        label="Initial Funding Amount"
                        value={initial_funding_amount}
                        name="initial_funding_amount"
                        disabled={!editable}
                        onChange={createChangeHandler(setInitial_funding_amount)}
                        required
                        expand
                      />
                    ) : ''}
                    {(type !== 'HEDGEHOG') && (type !== 'LANDING_ROCK') ? (
                      <MaskInput
                        mask_type="currency"
                        label="First Year Tax Free Distribution"
                        value={first_distribution}
                        disabled={!editable}
                        name="first_distribution"
                        onChange={createChangeHandler(setFirst_distribution)}
                        required
                      />
                    ) : ''}
                  </Grid>
                  {(type !== 'HEDGEHOG') && (type !== 'LANDING_ROCK') ? (
                    <Grid container spacing={2}>
                      {type === 'LIFE' ? (
                        <>
                          <MaskInput
                            mask_type="currency"
                            disabled={life_annual || !editable}
                            label="Projected Target Life Premium"
                            value={life_target}
                            name="life_target"
                            onChange={createChangeHandler(setLife_target)}
                          />
                          <MaskInput
                            mask_type="currency"
                            label="Death Benefit"
                            value={death_benefit}
                            disabled={!editable}
                            name="death_benefit"
                            onChange={createChangeHandler(setDeath_benefit)}
                          />
                        </>
                      ) : (
                        <MaskInput
                          mask_type="currency"
                          disabled={life_target || !editable}
                          label="Projected Annuity Amount"
                          value={life_annual}
                          name="life_annual"
                          onChange={createChangeHandler(setLife_annual)}
                        />
                      )}
                    </Grid>
                  ) : ''}
                </GridForm>
                {type === 'HEDGEHOG' ? (
                  <div>
                    <Box
                      sx={{
                        gridArea: 'row1',
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Typography variant="subtitle1" my={3}>Is this new business or a renewal?*</Typography>
                      <RadioGroup
                        aria-label="new_hh"
                        name="new_hh"
                        value={new_hh}
                        disabled={!editable}
                        onChange={(evt) => {
                          setNew_hh(evt.target.value === 'true');
                        }}
                        row
                      >
                        <FormControlLabel value={true} control={<Radio disabled={!editable} />} label="New Business" />
                        <FormControlLabel value={false} control={<Radio disabled={!editable} />} label="Renewal" />
                      </RadioGroup>
                    </Box>
                    <GridForm>
                      <Grid container spacing={2}>
                        <MaskInput
                          mask_type="currency"
                          label={new_hh ? 'New Contract Basis' : 'Previous Contract Basis'}
                          disabled={!editable && !closedEditable}
                          value={contract_basis}
                          name="contract_basis"
                          onChange={createChangeHandler(setContract_basis)}
                        />
                        {new_hh === false ? (
                          <MaskInput
                            mask_type="currency"
                            label="New Contract Basis"
                            disabled={!editable && !closedEditable}
                            value={additional_c_basis}
                            name="additional_c_basis"
                            onChange={createChangeHandler(setAdditional_c_basis)}
                          />
                        ) : ''}
                      </Grid>
                      <Grid container spacing={2}>
                        <Select
                          labelId="term-months-label"
                          label="Contract Term"
                          disabled={!editable && !closedEditable}
                          value={term_months}
                          name="term_months"
                          onChange={createChangeHandler(setTerm_months)}
                        >
                          <MenuItem value={12}>12 Months</MenuItem>
                          <MenuItem value={15}>15 Months</MenuItem>
                          <MenuItem value={18}>18 Months</MenuItem>
                          <MenuItem value={21}>21 Months</MenuItem>
                          <MenuItem value={24}>24 Months</MenuItem>
                          <MenuItem value={27}>27 Months</MenuItem>
                          <MenuItem value={30}>30 Months</MenuItem>
                          <MenuItem value={33}>33 Months</MenuItem>
                          <MenuItem value={36}>36 Months</MenuItem>
                        </Select>
                        <TextField
                          label="Interest Rate"
                          value={note_interest_rate}
                          disabled={!editable && !closedEditable}
                          name="note_interest_rate"
                          type="number"
                          onChange={createChangeHandler(setNote_interest_rate)}
                        />
                      </Grid>
                    </GridForm>
                  </div>
                ) : ''}
                <hr />
                <Box
                  fullWidth
                  sx={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(5, 1fr)',
                    gap: 1,
                    gridTemplateRows: 'auto',
                    gridTemplateAreas: `
                    "header header header header"
                    "row1 row1 row1 row1"
                    "row2 row2 row2 row2"
                    "row3 row3 row3 row3"
                  `,
                  }}
                >
                  {(type !== 'HEDGEHOG') && (type !== 'LANDING_ROCK') ? (
                    <Box
                      sx={{
                        gridArea: 'row1',
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      <MutedTypography variant="subtitle1">Is this a personal or spousal policy?</MutedTypography>
                      <RadioGroup
                        aria-label="personal-policy"
                        name="personal"
                        value={personal}
                        row
                        onChange={(evt) => {
                          setPersonal(evt.target.value === 'true');
                        }}
                      >
                        <FormControlLabel
                          value={true}
                          control={<Radio />}
                          disabled={!editable}
                          style={{ color: 'black' }}
                          label="Yes"
                        />
                        <FormControlLabel
                          value={false}
                          control={<Radio />}
                          disabled={!editable}
                          style={{ color: 'black' }}
                          label="No"
                        />
                      </RadioGroup>
                    </Box>
                  ) : ''}
                  <Box
                    sx={{
                      gridArea: 'row2',
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                  >
                    <MutedTypography variant="subtitle1">Is this a joint work case?*</MutedTypography>
                    <RadioGroup
                      aria-label="commission-split"
                      name="joint"
                      value={joint}
                      onChange={(evt) => {
                        const isJoint = evt.target.value === 'true';
                        setWriting_agent_split(isJoint ? 0 : 100);
                        setJoint(isJoint);
                      }}
                      row
                    >
                      <FormControlLabel
                        value={true}
                        control={<Radio />}
                        disabled={!editable}
                        label="Yes"
                      />
                      <FormControlLabel
                        value={false}
                        control={<Radio />}
                        disabled={!editable}
                        label="No"
                      />
                    </RadioGroup>
                  </Box>
                </Box>
                {
                joint
                  ? (
                    <Box
                      sx={{
                        display: 'grid',
                        gridTemplateColumns: 'repeat(4, 1fr)',
                        gap: 1,
                        gridTemplateRows: 'auto',
                        gridTemplateAreas: `
                          "header header header header"
                          "row1 row1 row1 row1"
                          "row2 row2 row2 row2"
                          "row3 row3 row3 row3"
                          "row4 row4 row4 row4"
                          "row5 row5 row5 row5"
                        `,
                      }}
                    >
                      <Box sx={{
                        gridArea: 'row1',
                        display: 'flex',
                        gap: 2,
                        marginBottom: 4,
                      }}
                      >
                        <div className={classes.splitsContainer}>
                          <MutedTypography variant="h6">Total Splits = </MutedTypography>
                          <MutedTypography variant="h6" className={validSplits() ? '' : classes.splitBad}>
                            {totalSplits}%
                          </MutedTypography>
                        </div>
                      </Box>
                      <Box sx={{
                        gridArea: 'row2',
                        display: 'flex',
                        justifyContent: 'space-between',
                        gap: 2,
                        marginBottom: 4,
                      }}
                      >
                        <Grid container spacing={2}>
                          <TextField
                            disabled
                            label={writingAgent.email}
                            xs={10}
                          />
                          <TextField
                            disabled={!editable}
                            label="Split percentage"
                            type="number"
                            value={writing_agent_split}
                            onChange={createChangeHandler(setWriting_agent_split)}
                            xs={2}
                          />
                        </Grid>
                      </Box>
                      <Box sx={{
                        gridArea: 'row3',
                        display: 'flex',
                        justifyContent: 'space-between',
                        gap: 2,
                        marginBottom: 4,
                      }}
                      >
                        <Grid container spacing={2}>
                          <Grid item xs={10}>
                            {editable ? (
                              <UserSearch
                                ignore={[props.user?.auth?.id]}
                                value={agent2}
                                onChange={setAgent2}
                              />
                            ) : (
                              <TextField
                                disabled
                                label={agent2 ? agent2.email : 'N/A'}
                                xs={12}
                              />
                            )}
                          </Grid>
                          <TextField
                            disabled={!editable}
                            label="Split percentage"
                            type="number"
                            value={agent2Split}
                            onChange={createChangeHandler(setAgent2Split)}
                            xs={2}
                          />
                        </Grid>
                      </Box>
                      <Box sx={{
                        gridArea: 'row4',
                        display: 'flex',
                        justifyContent: 'space-between',
                        gap: 2,
                        marginBottom: 4,
                      }}
                      >
                        <Grid container spacing={2}>
                          <Grid item xs={10}>
                            {editable ? (
                              <UserSearch
                                ignore={[props.user?.auth?.id]}
                                value={agent3}
                                onChange={setAgent3}
                              />
                            ) : (
                              <TextField
                                disabled
                                label={agent3 ? agent3.email : 'N/A'}
                                xs={12}
                              />
                            )}
                          </Grid>
                          <TextField
                            disabled={!editable}
                            label="Split percentage"
                            type="number"
                            value={agent3Split}
                            onChange={createChangeHandler(setAgent3Split)}
                            xs={2}
                          />
                        </Grid>
                      </Box>
                      <Box sx={{
                        gridArea: 'row5',
                        display: 'flex',
                        justifyContent: 'space-between',
                        gap: 2,
                        marginBottom: 4,
                      }}
                      >
                        <Grid container spacing={2}>
                          <Grid item xs={10}>
                            {editable ? (
                              <UserSearch
                                ignore={[props.user?.auth?.id]}
                                value={agent4}
                                onChange={setAgent4}
                              />
                            ) : (
                              <TextField
                                disabled
                                label={agent4 ? agent4.email : 'N/A'}
                                xs={12}
                              />
                            )}
                          </Grid>
                          <TextField
                            disabled={!editable}
                            label="Split percentage"
                            type="number"
                            value={agent4Split}
                            onChange={createChangeHandler(setAgent4Split)}
                            xs={2}
                          />
                        </Grid>
                      </Box>
                    </Box>
                  ) : ''
                }
                {bsfFiles?.length ? (
                  <div>
                    <MutedTypography variant="h6">BSF Files</MutedTypography>
                    {bsfFiles.map((f) => (
                      <div key={f.id}>
                        <a
                          href={`${config.API_URL}/bsfs/${id}/files/${f.id}/download`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {f.name}
                        </a>
                      </div>
                    ))}
                  </div>
                ) : ''}
                {closedEditable ? (
                  <div
                    style={{
                      marginTop: '20px',
                    }}
                  >
                    <hr />
                    <Box
                      sx={{
                        gridArea: 'row2',
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      <MutedTypography variant="subtitle1">Policy canceled or surrendered?</MutedTypography>
                      <RadioGroup
                        aria-label="canceled"
                        name="canceled"
                        value={canceled}
                        onChange={(evt) => {
                          const isCanceled = evt.target.value === 'true';
                          setCanceled(isCanceled);
                        }}
                        row
                      >
                        <FormControlLabel
                          value={true}
                          control={<Radio />}
                          disabled={!editable && !closedEditable}
                          label="Yes"
                        />
                        <FormControlLabel
                          value={false}
                          control={<Radio />}
                          disabled={!editable && !closedEditable}
                          label="No"
                        />
                      </RadioGroup>
                    </Box>
                  </div>
                ) : ''}
                {!isWriter ? (
                  <div className={classes.saveBtnContainer}>
                    <TextArea
                      placeholder={`Notes${isWriter ? '' : '(if rejecting)'}`}
                      rows={3}
                      value={rejectNotes}
                      className={classes.w400}
                      onChange={(e) => {
                        setRejectNotes(e.target.value);
                      }}
                    />
                  </div>
                ) : ''}
                <div className={classes.saveBtnContainer}>
                  <Button
                    gold
                    onClick={() => history.push(`/household/${client.household_id}/client/${client.id}`)}
                    sx={{ margin: '20px' }}
                  >
                    Back to Client Profile
                  </Button>
                  {editable ? (
                    <>
                      <div>
                        <Button
                          gold
                          onClick={deletePolicy}
                          sx={{ width: '100px', margin: '20px' }}
                          disabled={disabled}
                        >
                          Delete
                        </Button>
                      </div>
                      <div>
                        <Button
                          gold
                          onClick={handleResubmit}
                          sx={{ width: '100px', margin: '20px' }}
                          disabled={disabled}
                        >
                          Update
                        </Button>
                      </div>
                    </>
                  ) : ''}
                  {closedEditable ? (
                    <div>
                      <Button
                        gold
                        onClick={handleClosedEdit}
                        sx={{ width: '100px', margin: '20px' }}
                        disabled={saving || !submitted_at}
                      >
                        Update
                      </Button>
                    </div>
                  ) : ''}
                  {!isWriter && (policy.status !== 'CLOSED') ? (
                    <>
                      <div>
                        <Button
                          color="secondary"
                          onClick={() => handleSubmit(false)}
                          sx={{ width: '100px', margin: '20px' }}
                          disabled={!rejectNotes}
                        >
                          Reject
                        </Button>
                      </div>
                      <div>
                        <Button
                          color="primary"
                          onClick={() => handleSubmit(true)}
                          sx={{ width: '100px', margin: '20px' }}
                          disabled={policy.status === 'OPEN'}
                        >
                          Approve
                        </Button>
                      </div>
                    </>
                  ) : ''}
                </div>
                {payments?.length ? (
                  <div>
                    <Box>
                      <MutedTypography variant="h6">Payments</MutedTypography>
                      <Download rows={payments} name={`bsf_${policy.policy_number}_payments`} />
                    </Box>
                    <Box py={5}>
                      <div className={classes.gridContainer}>
                        <DataGrid columns={paymentColumns} rows={payments || []} />
                      </div>
                    </Box>
                  </div>
                ) : ''}
                {chargebacks?.length ? (
                  <div>
                    <Box>
                      <MutedTypography variant="h6">Chargebacks</MutedTypography>
                      <Download rows={chargebacks} name={`bsf_${policy.policy_number}_chargebacks`} />
                    </Box>
                    <Box py={5}>
                      <div className={classes.gridContainer}>
                        <DataGrid columns={chargebackColumns} rows={chargebacks || []} />
                      </div>
                    </Box>
                  </div>
                ) : ''}
                {log?.length ? (
                  <div>
                    <Box>
                      <MutedTypography variant="h6">History</MutedTypography>
                    </Box>
                    <Box py={5}>
                      <div className={classes.gridContainer}>
                        <DataGrid columns={logColumns} rows={log || []} />
                      </div>
                    </Box>
                  </div>
                ) : ''}
              </form>
            ) : <CircularProgress/>}
          </Paper>
        )}
      </div>
    </Layout>
  );
};

export default ReviewBSF;
